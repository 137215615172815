(function ($) {
  $(document).ready(function () {
    if (window.location.href.indexOf('#foundation_finder') !== -1) {
      var win_url = window.location.href;
      var quiz_url =
        win_url.indexOf('#foundation_finder' !== -1)
          ? '/foundation_finder/option.tmpl'
          : '/foundation_finder/option.tmpl?reset_date=1';
      var close_class = win_url.indexOf('#foundation_finder' !== -1) ? 'close-link' : '';
      var $iframe = $(
        '<div class="close-container"><a class="' +
          close_class +
          '">close</a></div><iframe id="foundation_finder_frame" height="576" frameborder="0" width="768" src="' +
          quiz_url +
          '" scrolling="no" horizontalscrolling="no" verticalscrolling="no" marginheight="0" marginwidth="0" style="border: none;"></iframe>'
      );

      generic.overlay.launch({
        content: $iframe,
        cssStyle: {
          padding: '0px',
          height: '575px',
          overflow: 'hidden',
          border: 'none'
        },
        cssClass: close_class,
        includeBackground: true
      });
    }
  });
})(jQuery);
